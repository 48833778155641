import firebase from 'firebase/app'

export const appName = 'lyuartwork-bb90e'

const config = {
  apiKey: 'AIzaSyA_GlyPYrgEya_phbsYfs4H83d5GS8xU60',
  authDomain: `${appName}.firebaseapp.com`,
  databaseURL: `https://${appName}.firebaseio.com`,
  projectId: appName,
  storageBucket: `${appName}.appspot.com`,
  messagingSenderId: '871737277909'
}

firebase.initializeApp(config)
