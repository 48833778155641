import React from 'react'
import { ReactComponent as FBLogo } from './svg/fb.svg'
import { ReactComponent as IGLogo } from './svg/insta.svg'
import { ReactComponent as TWLogo } from './svg/twitter.svg'
import { ReactComponent as VKLogo } from './svg/vk.svg'
import { ReactComponent as VMLogo } from './svg/vimeo.svg'
import { ReactComponent as BELogo } from './svg/be.svg'

const Root = () => {
  const videoLink = 'https://firebasestorage.googleapis.com/v0/b/lyuartwork-bb90e.appspot.com/o/lyuartwork.mp4?alt=media&token=78d76ea1-5317-48cd-a649-05878e9357cd'

  return (
    <div className='flow'>
      <div className='flow__video-wrapper'>
        <video className='flow__video' width='300' height='300' playsInline autoPlay muted>
          <source src={videoLink} type='video/mp4' />
        </video>
      </div>
      <div className='flow__social'>
        <a href='https://www.instagram.com/lyuartwork' className='contacts__social-link social-link' target='_blank' rel='noopener noreferrer'>
          <IGLogo title='Instagram' />
        </a>
        <a href='https://www.facebook.com/lyuartwork' className='contacts__social-link social-link' target='_blank' rel='noopener noreferrer'>
          <FBLogo title='Facebook' />
        </a>
        <a href='https://vimeo.com/lyuartwork' className='contacts__social-link social-link' target='_blank' rel='noopener noreferrer'>
          <VMLogo title='Vimeo' />
        </a>
        <a href='https://twitter.com/lyuartwork' className='contacts__social-link social-link' target='_blank' rel='noopener noreferrer'>
          <TWLogo title='Twitter' />
        </a>
        <a href='https://www.behance.net/indigoflow' className='contacts__social-link social-link' target='_blank' rel='noopener noreferrer'>
          <BELogo title='Behance' />
        </a>
        <a href='https://vk.com/lyuartwork' className='contacts__social-link social-link' target='_blank' rel='noopener noreferrer'>
          <VKLogo title='VK' />
        </a>
      </div>
    </div>
  )
}

export default Root